@import url("https://fonts.googleapis.com/css?family=Quicksand:light,regular,medium,semi,bold,italic");

body,
.quicksand-font,
h1,
h2,
h3,
h4,
h4,
h5,
h6,
p,
a,
button,
span {
  font-family: "Quicksand", sans-serif !important;
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
.text-gradient {
  background: -webkit-linear-gradient(#f5194a, #9b44bd);
  background-clip: inherit;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.body-bg {
  /* background-image: linear-gradient(to right, #162266, #10194b); */
  background-color: #0f1258;
  overflow: hidden;
}
.theme-btn {
  background-image: linear-gradient(to right, #09debc, #7834f6);
  border-radius: 40px;
  padding: 8px 25px !important;
  width: fit-content;
  color: white;
  border: none;
}
.modal-dialog .closeBtn {
  z-index: 99;
}

.team-box img {
  height: 100px;
  width: 100px;
  border-radius: 20px;
}

.main-section {
  background-size: cover;
  background-repeat: no-repeat;
}

header .navbar .navbar-brand img {
  width: 210px;
}

@media (max-width: 767px) {
  header .navbar .navbar-brand img {
    width: 180px;
  }
}
header .navbar .nav-link {
  font-weight: 500;
  font-size: 14px;
  color: white;
}
header .navbar .active {
  border-bottom: 2px solid #02eab8;
}
header .navbar .nav-item {
  margin-right: 35px;
}

.main-section .banner .contant {
  position: absolute;
  top: 50%;
  max-width: 243px;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.main-section .banner .contant h1 {
  color: white;
  font-size: 30px;
  font-weight: 600;
}
.main-section .banner .contant h4 {
  color: white;
  font-size: 16px;
  text-transform: uppercase;
}
.main-section .banner {
  padding: 40px 0 0 0;
  position: relative;
}
.main-section .banner .contant p {
  color: white;
  font-size: 13px;
}
.main-section .banner .card {
  border-color: #293eb5;
  padding: 10px;
  background-color: transparent;
}
.main-section .banner .card h5 {
  color: white;
  text-align: center;
}
.main-section .banner .card ul {
  text-align: center;
  list-style: none;
  padding: 0;

  margin-top: 20px;
}
.main-section .banner .card ul li {
  text-align: center;
  display: inline-block;
  width: 70px;
}
.main-section .banner .card ul li p {
  font-size: 14px;
  color: white;
  margin-top: 5px;
}
.main-section .banner .card ul li .box {
  width: 65px;
  height: 65px;
  border-radius: 5px;
  border: 1px solid #02eab8;
  text-align: center;
  font-size: 30px;
  padding-top: 10px;
  background-color: white;
}
.main-section .banner .card .form-group input {
  padding-left: 40px;
  border: 2px solid #293eb5;
  background: transparent;
  height: 45px;
}

.main-section .banner .card .form-group input[type="text"] {
  color: white !important;
}
.main-section .banner .card .form-group input::placeholder {
  color: #dadada;
}

/* .main-section .banner .card .form-group .theme-btn {
  border-radius: 0.25rem;
  margin-left: 2px;
  height: 45px;
} */

@media (max-width: 700px) {
  .main-section .banner .card ul li {
    width: 58px;
  }
  .main-section .banner .card ul li .box {
    width: 48px;
    height: 48px;
    border-radius: 5px;
    border: 1px solid #02eab8;
    text-align: center;
    font-size: 26px;
    padding-top: 4px;
    background-color: white;
  }
}
.status {
  margin-top: 30px;
  padding: 20px 0;
  background-image: linear-gradient(to right, #7834f6, #09debc);
}
.status h5 {
  color: white;
  border-bottom: 1px solid #02eab8;
  width: fit-content;
  margin: auto;
  font-size: 35px;
  font-weight: 600;
  margin-bottom: 15px;
}
.status p {
  color: white;
}
.video-sec {
  /* padding: 50px 0; */
  position: relative;
}
/* .video-bgsection {
  position: relative;
  min-height: 800px;
} */
/* .video-bgsection  .bg-video {
  position: absolute;
  height: 100%;
  top: 0;
} */
.video-bgsection .bg-video video {
  box-sizing: border-box;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-filter: brightness(50%);
  filter: brightness(100%);
  object-fit: cover;
}
.center-video {
}
@media (max-width: 700px) {
  .center-video {
    margin-top: 50px;
    position: unset;
    width: 100%;
    top: unset;
    transform: unset;
  }
  /* .bg-video {
    display: none;
  } */
}
.video-sec ul {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
.video-sec ul li {
  margin-right: 15px;
  z-index: 99;
}
.video-sec .video-bg {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 40px 0;
}

.catagory {
  background-size: cover;
  padding: 00px 0 350px 0;
  background-repeat: no-repeat;
}

.catagory .largebox {
  position: relative;
  width: 90%;
  height: 300px;
  margin-left: 10%;
}
.catagory-section {
  color: white;
  margin-top: 70px;
}
.catagory-section .row {
  margin-top: 6rem;
}
.catagory .largebox:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  background: linear-gradient(120deg, #00f260, #0575e6, #00f260);
  background-size: 300% 300%;
  clip-path: polygon(
    0% 100%,
    3px 100%,
    3px 3px,
    calc(100% - 3px) 3px,
    calc(100% - 3px) calc(100% - 3px),
    3px calc(100% - 3px),
    3px 100%,
    100% 100%,
    100% 0%,
    0% 0%
  );
}
.grndimgbox {
  width: 100%;
  height: 100%;
  position: absolute;
  top: -30px;
  left: -30px;
  z-index: 1;
}
.gridinnbx {
  position: relative;
  width: 100%;
  height: 100%;
}
.gridinnbx:before {
  content: "";
  background: url(../../assets/images/gridimg.png) no-repeat;
  width: 57px;
  height: 57px;
  position: absolute;
  right: -20px;
  bottom: -20px;
  z-index: -1;
  animation: grandvisionimgbox2 5s forwards;
}

.gridinnbx:after {
  content: "";
  background: url(../../assets/images/gridimg.png) no-repeat;
  width: 57px;
  height: 57px;
  position: absolute;
  top: -20px;
  left: 15px;
  margin-left: -40px;
  z-index: -1;
  animation: grandvisionimgbox 5s forwards;
}
@media (min-width: 1600px) {
  .catagory-container {
    max-width: 1500px !important;
  }
}

.catagory-section ul li {
  list-style: none;
}
.catagory-section ul li::before {
  content: "\2022";
  color: #54cbde;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.catagory-section h2 {
  color: #53d3ff;
  font-weight: bold;
  margin-bottom: 1.5rem;
}
.catagory-section h4 {
  color: #53d3ff;
  font-weight: bold;
  margin-top: 1.5rem;
}

.catagory-section h2 span {
  color: white;
  font-weight: bold;
}

.catagory-section ul {
  padding-left: 12px;
}
.catagory-section li {
  margin-bottom: 1rem;
}

.center-img {
  position: absolute;
  right: 0;
  bottom: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.right-side .grndimgbox {
  left: 40px;
}
@media (max-width: 600px) {
  .right-side {
    margin-left: 0 !important;
    margin-top: 40px;
  }
  .left-side {
    margin-bottom: 40px;
  }
}
@media (max-width: 768px) {
  .row-revers {
    flex-direction: column-reverse;
  }
  .catagory-section .row {
    margin-top: 4rem !important;
  }
}

.catagory .catagory-box {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  text-align: center;
}
.catagory .catagory-box .brand-logo {
  width: 180px;
  margin: 20px auto;
  display: block;
}

.Partners {
  padding: 50px 0px 50px 0px;
  text-align: center;
}

.Partners img {
  width: 278px;
  height: 80px;
}

.facny-team {
  margin-top: -270px;
  margin-bottom: 50px;
}
.facny-team .card {
  border-color: #324ace;
  padding: 20px;
  background-color: #29388b;
}

@media (max-width: 600px) {
  .main-section .banner .contant {
    text-align: center;
  }
  .main-section .banner .contant h1 {
    color: white;
    font-size: 20px;
    font-weight: 600;
  }
  .main-section .banner .contant h4 {
    color: white;
    font-size: 12px;
    text-transform: uppercase;
  }
  .main-section .banner .contant p {
    color: white;
    font-size: 10px;
  }
}
.faq {
  background-size: 290px 100%;
  background-position: left;
  background-repeat: no-repeat;
  padding: 40px 0;
}

.faq button {
  color: white;
  text-align: left;
  font-size: 16px;
  border-top: 1px solid #02eab8;
  border-radius: 0;
  width: 100%;
  text-decoration: none;
  white-space: normal;
  margin-top: 7px;
  background-color: transparent;
  padding-left: 0;
}
.faq button:focus {
  box-shadow: none !important;
}
.faq .card-body {
  padding: 1px 1.25rem;
  color: white;
  padding-left: 0 !important;
}

.faq button:after {
  font-family: "Font Awesome 5 Pro";
  content: "\f068";
  float: right;
}
.faq .minus-simble:after {
  content: "\f067" !important;
}
.faq h1 {
  font-size: 40px;
  text-align: center;
  color: white;
  margin-bottom: 30px;
}
.footer .main-footer {
  background-color: #10194b;
  padding: 40px 0;
}
.footer .main-footer .social {
  display: flex;
  align-items: center;
  list-style: none;
  flex-wrap: wrap;
  padding: 0;
  margin-top: 20px;
}
.footer .main-footer .social li {
  margin-right: 10px;
}
.footer .main-footer h4 {
  font-size: 18px;
  text-transform: capitalize;
  color: white;
  margin-bottom: 20px;
}
.footer .main-footer .contact a {
  color: #02eab8;
}
.footer .main-footer .contact p,
.footer .main-footer .useful-link ul a {
  color: #fff;
}
.footer .main-footer .useful-link ul {
  list-style: none;
  padding: 0;
}
.footer .main-footer .subscribe p {
  color: white;
}
.footer .main-footer .subscribe .input {
  display: flex;
  align-items: center;
  border: 1px solid #293eb5;
  border-radius: 10px;
  background-color: #172367;
  padding: 5px;
}

@media (max-width: 767px) {
  .footer .main-footer .subscribe .input {
    justify-content: space-between;
  }
}
.footer .main-footer .subscribe .input input {
  border: none;
  background-color: transparent;
  color: white;
}
.footer .main-footer .subscribe .input input:focus {
  box-shadow: none !important;
}
.footer .main-footer .subscribe .input button {
  background-image: linear-gradient(to right, #09debc, #7834f6);
  border: none;
  color: white;
}
.footer .footer-bottom {
  padding: 20px 0;
  background-image: linear-gradient(to right, #02eab8, #7f29f9);
}
.footer .footer-bottom p {
  color: white;
}
.footer .footer-bottom a {
  color: white;
}

.zoom,
.small {
  padding: 30px 0;
}
.zoom img {
  transition-duration: 1s;
  margin: 0 auto;
  display: block;
}
.zoom .main-img {
  width: 100px;
}
.zoom:hover .main-img {
  transform: scale(1.4);
  -webkit-transform: scale(1.4);
  -moz-transform: scale(1.4);
  z-index: 0;
}

.home-annimat-canvas {
  background: rgba(50, 50, 50, 0.05);
  width: 100%;
  height: 150%;
  position: absolute;
  opacity: 0.3;
}
@media (max-width: 760px) {
  .home-annimat-canvas {
    height: 350%;
  }
}
/* .side-bar{
  width: 300px;
} */
.contaant-area {
  width: 100%;
}
@media (min-width: 1000px) {
  .side-bar {
    width: 300px;
  }
}
.das-status {
  background-image: linear-gradient(to right, #02eab8, #7f29f9);
  margin: 0;
  border-radius: 5px;
}
.das-status .card {
  border: none;
  /* background-image: linear-gradient(to right, #02eab8, #7f29f9); */
  background-color: transparent;
}
.das-status .card h5 {
  color: #ffffff;
  font-size: 30px;
  font-weight: 700;
  text-align: center;
  padding: 10px;
}
.das-status .card p {
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
}
.my-progress {
  overflow: unset;
}
.my-progress .progress-bar {
  position: relative;
}
.my-progress .progress-bar span {
  position: absolute;
  bottom: -26px;
  color: black;
  z-index: 99999999;
  right: 0;
  font-size: 16px;
}
@media (max-width: 1000px) {
  .das-status .card {
    border-bottom: 2px solid #02eab8;
    border-radius: 0;
  }
  .das-status div:last-child .card {
    border-bottom: 0px;
  }
}
@media (max-width: 992px) {
  .main-section .banner .min-height {
    min-height: 355px;
  }
}
#bubbles {
  position: absolute;
  top: 0;
  height: 100%;
  left: 0;
  width: 100%;
  right: 0;
  bottom: 0;
}
.white-papaer-more .main-heading h3 {
  padding-left: 80px;
  position: relative;
}
.main-heading {
  margin: 30px 0px 20px 0px;
  position: relative;
}
.white-papaer-more .heading-bage {
  position: absolute;
  width: 62px;
  height: 36px;
  text-align: center;
  border-radius: 0px 10px 10px 0px;
  background-image: linear-gradient(to right, #09debc, #7834f6);
  color: white;
  font-weight: 700;
  left: 0;
  font-size: 21px;
}
.white-papaer-more .border-right {
  border-right: 3px solid #09debc !important;
}
.white-papaer-more .border-right p {
  padding-right: 10px;
}
.white-papaer-more ol {
  list-style: none;
  position: relative;
}
.white-papaer-more ol li::before {
  background-color: #00d0ff;
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  left: 0;
  border-radius: 100%;
  margin-top: 10px;
}
.white-papaer-more ol li {
  margin-bottom: 20px;
}
.white-papaer-more ol li p {
  margin: 0;
}
/* .white-papaer-more table{
  border-collapse: collapse;
  border-radius: 1em;
  overflow: hidden;
} */
.multiproducts-sec ul {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
}

.multiproducts-sec ul li {
  flex: 0 0 50%;
  max-width: 50%;
  padding-right: 40px;
  margin: 15px 0;
}
.multiproducts-sec ul li::before {
  display: none;
}

.multiproducts-sec ul li a {
  font-size: 20px;
  font-weight: 700;
  color: #fff;
  text-decoration: none;
  display: flex;
  align-items: center;
  transition: 0.3s all ease-in-out;
}

.multiproducts-sec ul li a:hover {
  color: #53d3ff;
}

.multiproducts-sec ul li a i {
  margin-right: 15px;
  min-width: 52px;
  flex: 0 0 52px;
  max-width: 52px;
  display: block;
  border-radius: 50%;
  vertical-align: middle;
}

.multiproducts-sec ul li a i svg {
  max-width: 52px;
}

@media (max-width: 575px) {
  .multiproducts-sec ul li a {
    font-size: 16px;
  }
}

@media (max-width: 575px) {
  .multiproducts-sec ul li {
    flex: 0 0 100%;
    max-width: 100%;
    padding-right: 0;
  }
}

.card-video {
  object-fit: fill;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.video-wrapper {
  width: 100%;
  height: 85vh;
  position: relative;
  overflow: hidden;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 769px) {
  .card-video {
    object-fit: cover;
  }
}

.grad-background {
  background-image: linear-gradient(to right, #09debc, #7834f6) !important;
}

.wallet-advertise {
  background-image: linear-gradient(90deg, #7834f6, #09debc);
  color: white;
  padding: 3px 20px;
  position: relative;
  /* margin-bottom: 15px; */
}
.wallet-advertise h1 {
  font-size: 25px;
  font-weight: 600;
  margin: 5px 0px;
  text-align: center;
  align-content: center;
  line-height: 1;
  align-self: center;
}

.wallet-advertise canvas {
  height: 100%;
  width: 100%;
}

.storeBtns__wrap {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 0 15px;
  gap: 0 15px;
  justify-content: center;
  margin: 5px 0px;
}

.storeBtns__btn {
  text-decoration: none !important;
  border: 0;
  background: linear-gradient(107deg, #0bd8bc, #0cd7bc 0, #713cf7);
  box-shadow: 0 20px 70px rgb(34 181 201 / 40%);
  border-radius: 30px;
  /* min-width: 152px; */
  padding: 15px 15px;
  height: 30px;
  color: #fff !important;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
  text-align: left;
  line-height: normal;
  display: flex;
  align-items: center;
  text-decoration: none;
  transition: all 0.4s;
}
.storeBtns__btn small {
  display: block;
}
@media (max-width: 1580px) {
  .storeBtns__btn {
    font-size: 12px;
    /* min-width: 150px;
    padding: 10px 20px;
    height: 42px; */
    margin: 3px 0px;
  }
}
.storeBtns__wrap img {
  /* margin: 0 5px; */
}

@media (min-width: 992px) {
  .wallet-advertise {
    display: flex;
    justify-content: space-between;
  }
}

@media (max-width: 600px) {
  .wallet-advertise h1 {
    font-size: 22px;
  }
}
