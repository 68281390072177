.AdminLogin {
  background: url(../../assets/images/banner-bg.svg) top center;
  display: flex;
  height: 100vh;
  width: 100%;
  justify-content: center;
  align-items: center;

  @media (max-width: 767px) {
    padding: 0 15px;
  }
}
.centerofScreen {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80%;
  .title {
    font-size: 20px;
    font-weight: 500;
    color: #fff;
    margin-bottom: 20px;
  }
}
.AdminCard {
  background: #1a2770;
  padding: 40px 30px;
  width: 100%;
  border-radius: 10px;
  h4 {
    text-align: center;
    margin-bottom: 25px;
    color: #fff;
    font-weight: 500;
  }
}
.logForm {
  max-width: 550px;
  input {
    background: transparent !important;
    border: 0.5px solid rgba(255, 255, 255, 0.4);
    height: 48px;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.6);
    &:focus {
      color: #fff;
    }
  }
  .theme-btn {
    margin: 0 auto !important;
    display: flex;
  }
}
.commonTop {
  padding-top: 30px;
}
.topinfo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 20px;
  .title {
    font-size: 20px;
    font-weight: 500;
    color: #fff;
    margin-bottom: 0;
  }
  p {
    font-size: 16px;
    font-weight: 400;
    color: #fff;
    margin-bottom: 0;
  }
  .searchBox {
    position: relative;
    @media (max-width: 767px) {
      margin-top: 15px;
    }
    input {
      background-color: transparent;
      border: 1px solid #1a2770;
      border-radius: 10px;
      padding: 10px 15px;
      color: #fff;
      box-shadow: rgba(0, 0, 0, 0.35) 0px -50px 36px -28px inset;
    }
    .searchImg {
      width: 25px;
      filter: invert(40%);
      -webkit-filter: invert(40%);
      position: absolute;
      top: 50%;
      right: 9px;
      transform: translateY(-50%);
      transition: all 0.3s ease-in-out;
    }
    input:focus + .searchImg {
      filter: invert(100%);
      -webkit-filter: invert(100%);
    }
  }
}

.adminInfoData {
  background-color: #1a2770;
  border-radius: 15px;
  box-shadow: 0 0px 10px rgba(255, 255, 255, 0.3);
  padding: 35px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  h4 {
    font-size: 24px;
    font-weight: 600;
    color: #fff;
    margin-bottom: 10px;
  }
  p {
    font-size: 16px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.6);
  }
}
.commonTop {
  table {
    // border: 0.5px solid rgba(255,255,255, 0.3)  ;
    white-space: nowrap;
    thead {
      tr {
        th {
          background: #1a2770;
          color: #fff;
          border: 0px solid #000;
          font-size: 16px;
        }
      }
    }
    tbody {
      tr {
        td {
          vertical-align: middle;
          color: #fff;
          font-size: 14px;
          border-top: 0.5px solid rgba(255, 255, 255, 0.3);
        }
      }
    }
  }
}

.graphclass {
  margin-bottom: 50px;
  padding-bottom: 300px;
  canvas {
    opacity: 1 !important;
  }
}

.react-confirm-alert-overlay {
  background-color: rgba(0, 0, 0, 0.6);

  .react-confirm-alert {
    border-radius: 10px;
    border: 2px solid #02eab8;
    padding: 40px 25px;
    background: #162266;
    color: #fff;
    text-align: center;

    h1 {
      font-size: 32px;
      font-weight: 600;
    }

    .logOutBtn_wrap {
      display: flex;
      justify-content: center;
      gap: 10px;
      button {
        padding: 5px 30px;
        border-radius: 30px;
        border: 0;
        background-color: #02eab8;
      }
    }
  }
}
.pagination {
  margin: 0 auto;
  text-align: center;
}
.pagination li {
  margin-left: 2px;
  margin-right: 2px;
}
.pagination li a {
  color: #fff;
  display: inline-block;
  padding: 5px;
  text-decoration: none;
  font-size: 18px;
  background: rgba(255, 255, 255, 0.4);
  border-radius: 2px;
  min-width: 40px;
}
.pagination li.active a {
  background: rgba(255, 255, 255, 0.8);
  color: blue;
}
