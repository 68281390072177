.old-header .navbar {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999;
  background-color: #1a2771;
}
.old-header .navbar .navbar-brand img {
  width: 120px;
}
.old-header .navbar .nav-item .nav-link {
  font-size: 15px;
  font-weight: 500;
  color: white;
  padding: 13px 15px;
}
.old-header .navbar-nav .active > .nav-link {
  color: #02eab8;
}
.old-header .navbar-nav .dropdown-menu {
  border-radius: 10px;
  border: 2px solid #02eab8;
  box-shadow: 0px 0px 15px -3px rgb(2 234 184);
  -webkit-box-shadow: 0px 0px 15px -3px rgb(2 234 184);
  -moz-box-shadow: 0px 0px 15px -3px rgba(2, 234, 184, 1.75);
}

header .navbar .navbar-brand img {
  width: 210px;
}
header .navbar .nav-link {
  font-weight: 500;
  font-size: 14px;
  color: white;
}
header .navbar .active {
  border-bottom: 2px solid #02eab8;
}
header .navbar .nav-item {
  margin-right: 15px !important;
}

.theme-btn {
  background-image: linear-gradient(to right, #09debc, #7834f6);
  border-radius: 40px;
  padding: 8px 25px !important;
  width: fit-content;
  color: white;
  border: none;
}
.advertise {
  background-image: linear-gradient(90deg, #7834f6, #09debc);
}
.advertise p {
  color: white;
  font-weight: 500;
  font-size: 18px;
  margin: 0;
}

@media (max-width: 600px) {
  .advertise p {
    font-size: 14px;
  }
}
