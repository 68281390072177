.Dashboard {
  background-size: cover;
  background-color: #001068;
  min-height: 100vh;
}

.fa-eye-slash {
  position: absolute;
  right: 10px;
  top: 12px;
  font-size: 15px;
  cursor: pointer;
}

.fa-eye {
  position: absolute;
  right: 10px;
  top: 12px;
  font-size: 15px;
  cursor: pointer;
}
