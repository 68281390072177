.process_cl {
  .modal-dialog {
    @media (max-width: 767px) {
      max-width: 500px;
    }
    @media (max-width: 599px) {
      max-width: 350px;
      margin: auto;
    }
    @media (max-width: 380px) {
      max-width: 300px;
    }
  }

  .closeBtn {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    border: 0;
    color: #fff;
    font-size: 24px;
    font-weight: 600;
    cursor: pointer;
    @media (max-width: 767px) {
      font-size: 16px;
    }
    @media (max-width: 380px) {
      font-size: 13px;
    }
  }
  .modal-header {
    justify-content: flex-end;
  }
  .modal-body {
    padding: 40px 65px 20px;
    min-height: 400px;
    @media (max-width: 767px) {
      padding: 20px 40px 10px;
      min-height: 350px;
    }
    h4 {
      font-weight: 600;
      font-size: 24px;
      @media (max-width: 767px) {
        font-size: 18px;
      }
      @media (max-width: 600px) {
        font-size: 12px;
      }
      @media (max-width: 480px) {
        font-size: 12px;
      }
      @media (max-width: 380px) {
        font-size: 10px;
      }
    }
    p {
      font-size: 18px;
      font-weight: 500;
      line-height: normal;
      @media (max-width: 767px) {
        font-size: 16px;
      }
      @media (max-width: 380px) {
        font-size: 13px;
      }
    }

    ul {
      list-style: none;
      padding-left: 0;
      li {
        font-size: 16px;
        @media (max-width: 767px) {
          font-size: 13px;
          line-height: 24px;
        }
        @media (max-width: 380px) {
          font-size: 10px;
          line-height: 18px;
        }
      }
    }
  }
  .itms_crousel {
    color: white !important;
    transition: none !important;
    ul {
      line-height: 30px;
    }
  }
  .carousel {
    height: 100%;
  }
  .carousel-control-next,
  .carousel-control-prev {
    position: absolute;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15%;
    padding: 0;
    color: #fff;
    text-align: center;
    background: 0 0;
    border: 0;
    opacity: 0.5;
    transition: opacity 0.15s ease;

    .visually-hidden {
      display: none;
    }
  }

  .carousel-control-prev {
    left: -75px;
    @media (max-width: 767px) {
      left: -48px;
    }
  }

  .carousel-control-next {
    right: -75px;
    @media (max-width: 767px) {
      right: -48px;
    }
  }
  .header_bg {
    border-bottom: unset;
  }
  .carousel-indicators {
    bottom: 0 !important;

    button {
      pointer-events: none;
      border: 1px solid #02eab8;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      margin: 5px;
      background-color: transparent;
      @media (max-width: 767px) {
        width: 20px;
        height: 20px;
      }
      &::before {
        content: "";
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 13px;
        font-weight: 600;
        color: #fff;
        @media (max-width: 767px) {
          font-size: 9px;
        }
      }
      &:nth-child(1) {
        &::before {
          content: "1";
        }
      }
      &:nth-child(2) {
        &::before {
          content: "2";
        }
      }
      &:nth-child(3) {
        &::before {
          content: "3";
        }
      }
      &:nth-child(4) {
        &::before {
          content: "4";
        }
      }
      &.active {
        background-color: #02eab8;
        &::before {
          color: #000;
        }
      }
    }
  }
}

.btn_fleximg {
  align-items: baseline;
  img {
    margin-left: 15px;
    cursor: pointer;
    @media (max-width: 768px) {
      height: 18px;
      width: 18px;
      margin-left: 10px;
    }
    @media (max-width: 768px) {
      height: 14px;
      width: 14px;
      margin-left: 4px;
    }
  }
}
