.Dashboard {
  background-size: cover;
  background-color: #001068;
  min-height: 100vh;
}
.Dashboard p,
.Dashboard h5 {
  margin-bottom: 1rem;
}
.transaction-history table {
  color: white;
}
.transaction-history p {
  color: white;
}
.transaction-history h6 {
  color: white;
  font-weight: 600;
}
.imgStyle {
  width: 16px;
  height: 16px;
  margin-left: 10px;
  cursor: pointer;
}
.timers {
  padding: 10px;
  margin: 10px;
}
.table_white_spce {
  white-space: nowrap;
}
@media (max-width: 480px) {
  .btn-registration {
    width: 56% !important;
    margin: auto;
  }
}
