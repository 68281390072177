
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}


.loading {
  -webkit-animation: rotation 10s infinite linear;
  position: absolute;
  left: 0;
  width: 100%;
}



@keyframes move_wave {
  0% {
    transform: translateX(0) translateZ(0) scaleY(1);
  }
  50% {
    transform: translateX(-25%) translateZ(0) scaleY(0.55);
  }
  100% {
    transform: translateX(-50%) translateZ(0) scaleY(1);
  }
}

.waveWrapperInner {
  position: absolute;
  width: 100%;
  overflow: hidden;
  height: 600px;
  top: 20%;
}

.bgTop {
  z-index: 0;

  height: 400px;
  background-size: contain;
  min-height: 400px; 
}
.bgMiddle {
  z-index: 0;

  height: 400px;
  background-size: contain;
  min-height: 400px;
}
.bgBottom {
  z-index: 0;
  height: 400px;
  background-size: contain;
  min-height: 400px;
}
.bgTop .waveTop {
  height: 400px;
  background-size: contain;
  min-height: 400px;
}
.bgMiddle .waveMiddle {
  height: 400px;
  background-size: contain;
  min-height: 400px;
}
.bgBottom .waveBottom {
  height: 400px;
  background-size: contain;
  min-height: 400px;
}
.wave {
  position: absolute;
  left: 0;
  width: 200%;
  height: 100%;
  background-repeat: repeat no-repeat;
  background-position: 0 bottom;
  transform-origin: center bottom;
}
.waveTop {
  background-size: 50% 100px;
}
.waveAnimation .waveTop {
  animation: move-wave 3s;
  -webkit-animation: move-wave 3s;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}
.waveMiddle {
  background-size: 50% 120px;
}
.waveAnimation .waveMiddle {
  animation: move_wave 10s linear infinite;
}
.waveBottom {
  background-size: 50% 100px;
}
.waveAnimation .waveBottom {
  animation: move_wave 15s linear infinite;
}
.waveAnimation .waveTop {
  animation: move_wave 15s linear infinite;
}
