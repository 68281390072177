@media (max-width: 1000px) {
  .inside-sidebar .navbar .navbar-collapse {
    display: none !important;
  }
  .inside-sidebar .navbar-toggler {
    display: none;
  }
  .inside-sidebar .navbar {
    justify-content: center;
  }
}
#accordion {
  height: 100%;
}

.progress {
  height: 23px;
  border-radius: 20px;
  font-size: 13px;
  font-weight: 600;
}
.progress-bar {
  background: linear-gradient(to right, #02eab8, #7f29f9);
  height: 23px;
}
.token-formula input {
  /* border-top: 0;
  border-left: 0;
  border-right: 0;
  border-radius: 0; */
  margin-bottom: 10px;
  text-align: center;
}
.token-formula select {
  /* border: 0; */
  padding-right: 10px;
  background-position: center right 20px;
}
.Dashboard {
  background-size: cover;
  background-color: #001068;
  min-height: 100vh;
}
.Dashboard p,
.Dashboard h5 {
  margin-bottom: 1rem;
}
.timer-dark p {
  color: #000 !important;
}

@media (max-width: 467px) {
  .timer-dark {
    flex-direction: column;
  }

  .countdownTimer {
    padding-top: 20px;
  }
  .countdownTimer .box {
    width: 45px;
    height: 45px;
    font-size: 20px;
  }
}
.countdownTimer {
  /* width: 100%; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.countdownTimer .box {
  width: 65px;
  height: 65px;
  border-radius: 5px;
  border: 1px solid #02eab8;
  text-align: center;
  font-size: 30px;
  padding-top: 0;
  background-color: white;
  margin: 0 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.box h5 {
  margin: 0 !important;
}
.countdownTimer p {
  font-size: 16px;
  margin-top: 10px;
  text-align: center;
}

.textCenter {
  width: 100%;
  text-align: center;
}

@media (max-width: 467px) {
  .countdownTimer .box {
    width: 45px;
    height: 45px;
    font-size: 20px;
  }
  .countdownTimer p {
    font-size: 12px;
    margin-top: 5px;
  }
}

@media (max-width: 600px) {
  .import-desktop {
    display: none;
  }
  .import-mobile {
    display: inline-block !important;
    margin: 0px 0px 10px 0px !important;
  }
}
