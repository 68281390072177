.verify_div {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 150px;
}
.verify_div p {
  font-size: 20px;
  color: black;
  text-align: center;
}
