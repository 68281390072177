
.footer .main-footer {
    background-color: #10194b;
    padding: 40px 0;
  }
  .footer .main-footer .social {
    display: flex;
    align-items: center;
    list-style: none;
    flex-wrap: wrap;
    padding: 0;
    margin-top: 20px;
  }
  .footer .main-footer .social li {
    margin-right: 10px;
  }
  .footer .main-footer h4 {
    font-size: 18px;
    text-transform: capitalize;
    color: white;
    margin-bottom: 20px;
  }
  .footer .main-footer .contact a {
    color: #02eab8;
    overflow-wrap: break-word;
  }
  .footer .main-footer .contact p,
  .footer .main-footer .useful-link ul a {
    color: #fff;
  }
  .footer .main-footer .useful-link ul {
    list-style: none;
    padding: 0;
  }
  .footer .main-footer .subscribe p {
    color: white;
  }
  .footer .main-footer .subscribe .input {
    display: flex;
    align-items: center;
    border: 1px solid #293eb5;
    border-radius: 10px;
    background-color: #172367;
    padding: 5px;
  }
  .footer .main-footer .subscribe .input input {
    border: none;
    background-color: transparent;
    color: white;
  }
  .footer .main-footer .subscribe .input input:focus {
    box-shadow: none !important;
  }
  .footer .main-footer .subscribe .input button {
    background-image: linear-gradient(to right, #09debc, #7834f6);
    border: none;
    color: white;
  }
  .footer .footer-bottom {
    padding: 20px 0;
    background-image: linear-gradient(to right, #02eab8, #7f29f9);
  }
  .footer .footer-bottom p {
    color: white;
  }
  .footer .footer-bottom a {
    color: white;
  }