.product-handle,
.crypto-handle {
    position: relative;
    background-image: url(../../assets/images/wallet/products-bg.png);
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: top center;
}

.crypto-hero {
    img {
        max-width: 100%;
    }
    .upper {
        font-size: 50px;

        @media (max-width:1539px) { 
            font-size: 40px;

        }
        @media (max-width:467px) { 
            font-size: 20px;
            display: block !important;

        }
        
    }
    .thinner {
        font-weight: normal;
    }
    @media (min-width:1440px) {
        .left[class*="col-"]{
            flex: 0 0 47%;
max-width: 47%;
        }
        .right[class*="col-"] {
            flex: 0 0 53%;
max-width: 53%;
        }
    }
    @media (max-width:767px) {  
        padding-top: 100px;
        
        img {
            display: table;
            max-width: 400px;
            margin:  0 auto;
            width: 100%;
            margin-top: 35px;
            margin-bottom: 50px;
        }
    .minHeight {
        min-height: inherit;

    }
    }
}

.product-handle {
    scroll-behavior: smooth;
    .wallet__dotlist {
        .wallet__dotitem {
            font-weight: normal;
            font-size: 16px;
        }
    }

    &-container>.row {
        scroll-margin-top: 90px;
        @media (max-width:767px) {
        scroll-margin-top: 60px;
            
        }
        > div {
            &:first-child {
                @media (min-width:1280px) {
                padding-right: 100px;
                }
                
            }
            // &:last-child {
            //     img {
            //         @media (max-width:767px) {
            //         display: table;
            //         margin: 0 auto;
            //         max-width: 500px;
            //         width: 100%;
            //         margin-bottom: 25px;
            //         margin-top: 15px;
            //     }
            //     }
            // }
                 img {
                    @media (max-width:767px) { 
                    display: table;
                    margin: 0 auto;
                    max-width: 500px;
                    width: 100%;
                    margin-bottom: 25px;
                    margin-top: 15px;
                }
                }
         }

        &:first-child {
            min-height: 100vh;
            @media (max-width:767px) { 
                padding-top: 100px;
            }
        }
        &:nth-child(2n) {
            flex-direction: row-reverse;
            > div {
                &:first-child {
                    @media (min-width:1280px) {
                    padding-right: 0;
                    padding-left: 100px;
                        
                    }
                }
            }
        }

        .left {
            padding: 25px 0;
            p {
                margin-top: 15px;
                line-height: 30px;
            }
        }

        .right {
            img {
                max-width: 100%;
            }
        }
    }
}

.container-inner {
    max-width: 1366px !important;
}






////////////////////////////// new landing page  /////////////////////////////

.mainCont {
    background-color: #0f1258;
    color: #fff;
  }
  
  .minHeight {
    min-height: 100vh;
  }
  .paddingBig {
    padding: 100px 0;
    @media (max-width: 1580px) {
      padding: 50px 0;
    }
  }
  .paddingSmall {
    padding: 70px 0;
    @media (max-width: 1580px) {
      padding: 30px 0;
    }
  }
  
  .commnCont {
    &.container {
      height: 100%;
      // min-height: 100vh;
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 1366px !important;
  
      @media (max-width: 1580px) {
        max-width: 1200px !important;
      }
      .row {
        align-items: center;
      }
    }
  }
  
  .wallet {
    &__sec {
      background-image: url(../../assets/images/wallet/wallet_layer.svg);
      background-repeat: no-repeat;
      background-position: left bottom;
      padding-top: 70px;
      @media (max-width: 1199px) {
        padding: 50px 0;
  
        .row {
          flex-direction: column-reverse;
        }
      }
    }
    &__content {
      @media (max-width: 1199px) {
        max-width: 767px;
        margin: 0 auto;
      }
      p {
        font-size: 16px;
        font-weight: 300;
        line-height: 26px;
        margin-bottom: 20px;
        @media (max-width: 1580px) {
          font-size: 14px;
          margin-bottom: 15px;
        }
        @media (max-width: 467px) {
          font-size: 11px;
          line-height: 20px;
          margin-bottom: 10px;
        }
      }
    }
  
    &__dotlist {
      padding-left: 0;
      list-style: none;
      margin-bottom: 28px;
      position: relative;
  
      &--line {
        &::before {
          content: "";
          display: inline-block;
          position: absolute;
          width: 1px;
          height: 80%;
          border-right: 2px dashed #fff;
          left: 10px;
        }
      }
    }
    &__dotitem {
      padding-left: 20px;
      font-size: 16px;
      // font-weight: 300;
      margin-bottom: 10px;
      position: relative;
      // @media (max-width: 1580px) {
      //   font-size: 14px;
      // }
      @media (max-width: 467px) {
        // font-size: 11px;
        margin-bottom: 5px;
      }
      &::before {
        content: "";
        position: absolute;
        top: 8px;
        left: 0;
        display: inline-block;
        border-radius: 50%;
        width: 7px;
        height: 7px;
        background: linear-gradient(135deg, #0bd8bc 0%, #0cd7bc 0%, #713cf7 100%);
        box-shadow: 0px 20px 70px #22b5c966;
      }
      &--big {
        padding-left: 50px;
        margin-bottom: 30px;
  
        &::before {
          top: 0;
          width: 22px;
          height: 22px;
          z-index: 1;
        }
  
        &::after {
          position: absolute;
          top: -9px;
          left: -9px;
          content: "";
          width: 40px;
          height: 40px;
          display: inline-block;
          background-color: #0f1258;
          border: 1px dashed rgba(83, 103, 229, 0.45);
          border-radius: 50%;
        }
  
        h6 {
          font-size: 18px;
          font-weight: 700;
          color: #50d1fd;
          @media (max-width: 1580px) {
            font-size: 16px;
          }
          @media (max-width: 467px) {
            font-size: 13px;
            margin-bottom: 5px;
          }
        }
      }
    }
  }
  
  .airdropSec {
    background-image: url(../../assets/images/wallet/airdrop_layer.svg);
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center bottom;
    @media (max-width: 1199px) {
      .row {
        flex-direction: column-reverse;
      }
    }
  }
  
  .chatSec {
    background-image: url(../../assets/images/wallet/chat_layer.svg);
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center top;
  }
  
  .debitcardSec {
    background-image: url(../../assets/images/wallet/debitSec_layer.svg);
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: right bottom -15px;
    @media (max-width: 1199px) {
      .row {
        flex-direction: column-reverse;
      }
    }
  }
  
  .swapSec {
    @media (max-width: 1199px) {
      .row {
        flex-direction: column-reverse;
      }
    }
  }
  
  .wallet__content p {
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
  }