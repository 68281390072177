.contact-us {
  /* margin: 76px 0 0 0; */
  padding: 50px 0;
}
.blue-text {
  color: #02eab8;
}
.contact-us .contact-card {
  border-radius: 10px;
  border: 2px solid #02eab8;
  padding: 20px;
  box-shadow: 0px 0px 19px -3px rgb(2 234 184);
  -webkit-box-shadow: 0px 0px 19px -3px rgb(2 234 184);
  -moz-box-shadow: 0px 0px 19px -3px rgba(2, 234, 184, 1.75);
}
.contact-us .contact-card .form-group {
  position: relative;
}
.contact-us .contact-card .form-group input {
  padding-left: 40px;
  border: 2px solid #02eab8;
  background: transparent;
  height: 50px;
}
.contact-us .contact-card .form-group textarea {
  padding-left: 40px;
  border: 2px solid #02eab8;
  background: transparent;
  resize: none;
}
.contact-us .contact-card .form-group i {
  position: absolute;
  color: #02eab8;
  top: 17px;
  left: 15px;
}
.contact-us .contact-card .theme-btn {
  border: 2px solid #02eab8;
  padding: 9px 50px;
}
.contact-us .contact-info i {
  color: #da1642;
  font-size: 35px;
}
.signup .upload-id .upload-box {
  border: 2px solid #02eab8;
  border-style: dashed;
  padding: 10px;
  position: relative;
}
.signup .upload-id .upload-box .upload-area i {
  font-size: 40px;
}
.signup .upload-id .upload-box .upload-area input {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  cursor: pointer;
  opacity: 0;
}
.connectRegidterBtn {
  position: absolute;
  top: 69%;
  left: auto;
}
@media (max-width: 477px) {
  .connectRegidterBtn {
    position: static;
  }
}
.connectRegidterBtn {
  /* position: absolute;
  top: 148px;
  bottom: 35px;
  padding: inherit;
  margin-top: 30px !important;
  left: 35px; */
}

/* @media (max-width: 580px) {
  .connectRegidterBtn {
    position: unset;
    left: 0;
  }

  .connectRegidterBtn .theme-btn {
    margin-top: 0 !important;
  }
} */
