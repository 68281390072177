.open-menu,
.close-menu {
  cursor: pointer;
  border: none;
  outline: none;
  color: #252a32;
  background: none;
}

.close-menu {
  position: absolute;
  top: 0;
  right: 1rem;
  border: none;
  outline: none;
  color: #252a32;
  background: none;
}

.brand {
  font-family: inherit;
  font-size: 1.75rem;
  font-weight: 700;
  line-height: 1.5;
  color: #d32f2f;
  text-transform: uppercase;
  text-rendering: optimizeLegibility;
}

.title-large {
  font-family: inherit;
  font-size: 2.25rem;
  font-weight: 700;
  line-height: inherit;
  color: #252a32;
  text-align: center;
  text-transform: capitalize;
}

.header {
  height: 100%;
  /* position: relative;
  width: 100%;
  height: auto;
  padding: 0.75rem 1.5rem;
  color: #252a32;
  background: #ffffff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 3px rgba(0, 0, 0, 0.24); */
}
@media(min-width:990px){
.dash-sidebar .navbar {
  display: unset;
  align-items: unset;
  justify-content: unset;
  align-items: center;
  padding: 0px;
  height: 100%;
}
}

@media(max-width:990px){
  .dash-sidebar .navbar {
    padding:0px;
  }
}

.dash-sidebar .navbar .menu-wrapper,
.dash-sidebar .navbar .menu {
  height: 100%;
}
.dash-sidebar .navbar .menu-wrapper::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  transition: background 0.5s;
}
.dash-sidebar .navbar .menu-wrapper.off-menu .menu {
  transform: translate3d(0, 0, 0);
  transition-duration: 0.7s;
  transition-delay: 0.2s;
  background-color: #0f1c65;
}
.dash-sidebar .mobile-bar {
  display: none;
}
.dash-sidebar .navbar .menu {
  width: 100%;
  z-index: 2;
  overflow-y: auto;
  color: #252a32;
  background: #ffffff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 3px rgba(0, 0, 0, 0.24);
}
.dash-sidebar .navbar .menu-block {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.dash-sidebar .navbar .menu-link {
  font-family: inherit;
  font-size: 1rem;
  font-weight: 600;
  line-height: inherit;
  text-transform: uppercase;
  transition: color 0.35s ease-out;
}

.dash-sidebar .navbar .menu-item {
  position: relative;
}
.dash-sidebar .navbar .menu-item a {
  font-family: inherit;
  font-size: 14px;
  font-weight: 600;
  line-height: inherit;
  text-transform: uppercase;
  padding: 0.5rem 1rem;
  display: block;
  color: #fff;
  transition: color 0.35s ease-out;
  text-decoration: none;
}
.dash-sidebar .navbar .menu-item a:hover {
  color: #7834f6;
}
.dash-sidebar .navbar .menu-item.has-collapsible {
  position: relative;
}
.dash-sidebar .navbar .menu-item.has-collapsible .menu-child {
  display: block;
  border: none;
  list-style: none;
  padding: 0;
}
.dash-sidebar
  .navbar
  .menu-item.has-collapsible
  .menu-child
  .menu-child-item
  a {
  font-family: inherit;
  font-size: 13px;
  font-weight: 400;
  line-height: inherit;
  padding: 0.25rem;
  color: #ffff;
  padding-left: 2.5rem;
  text-transform: uppercase;
  transition: color 0.35s ease-out;
}
.header
  .navbar
  .menu-item.has-collapsible
  .menu-child
  .menu-child-item
  a:hover {
  color: #ffff;
}
.dash-sidebar .navbar .menu-item.has-collapsible span::after {
  font-family: "Font Awesome 5 Pro";
  content: "\f107";
  font-size: 1.5rem;
  font-weight: 400;
  line-height: inherit;
  position: absolute;
  top: 0.15rem;
  right: 1rem;
  color: #fff;
  transition: all 0.35s ease;
}
.dash-sidebar .navbar .menu-item.has-collapsible span::after:hover {
  color: #ffff;
}
.dash-sidebar .navbar .menu-item.active.has-collapsible .menu-child {
  display: block;
  transition: all 0.35s ease;
}
.dash-sidebar .navbar .menu-item.active.has-collapsible span::after {
  transform: rotate(90deg);
}
.dash-sidebar .open-menu {
  position: absolute;
  top: -47px;
  color: white;
  right: 0;
  display: none;
  right: 6px;
}
.dash-sidebar .close-menu {
  display: none;
}
.dash-sidebar .navbar .menu {
  margin-top: 0px;
  list-style: none;
  padding: 0;
  background-color: #0f1c65;
}
.dash-sidebar .navbar .active {
  border: none;
  background-image: linear-gradient(to right, #09debc, #7834f6);
  width: 100%;
}

@media (max-width: 1000px) {
  .dash-sidebar .navbar .menu {
    position: fixed;
    display: flex;
    flex-direction: column;
    top: 0;
    left: 0;
    bottom: 0;
    max-width: 20rem;
    width: 100%;
    padding: 1.5rem 1rem;
    z-index: 2;
    overflow-y: auto;
    color: #252a32;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 3px rgba(0, 0, 0, 0.24);
    transform: translate3d(-100%, 0, 0);
    transition: transform 0.5s cubic-bezier(0.23, 1, 0.32, 1);
  }
  .dash-sidebar .open-menu,
  .dash-sidebar .close-menu {
    display: block;
  }
  .dash-sidebar .navbar .menu-block {
    margin-bottom: 3rem;
  }
  .dash-sidebar .mobile-bar {
    display: block;
  }
}

@media (max-width: 600px) {
  .import-desktop {
    display: none;
  }
  .import-mobile {
    display: block;
  }
}

.import-mobile {
  display: none;
}
