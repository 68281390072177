.imgDiv {
  position: relative;

  @media (max-width: 1199px) {
    max-width: 450px;
    margin: 0 auto;
  }
  @media (max-width: 467px) {
    max-width: 280px;
  }
  .imgBg {
    max-width: 740px;
    @media (max-width: 1580px) {
      max-width: 670px;
    }
    @media (max-width: 1199px) {
      max-width: 465px !important;
    }
    @media (max-width: 467px) {
      max-width: 280px !important;
    }
  }
  .imgMain {
    max-width: 560px;
    position: absolute;
    top: 78px;
    left: 50%;
    transform: translate(-50%, 0);
    @media (max-width: 1580px) {
      max-width: 490px;
      top: 90px;
    }
    @media (max-width: 1199px) {
      max-width: 320px !important;
      top: 65px;
    }
    @media (max-width: 467px) {
      max-width: 200px !important;
    }
  }
  &.cryptoImg {
    .imgBg {
      @media (max-width: 1199px) {
        max-width: 465px;
      }
    }
    .imgMain {
      @media (max-width: 1199px) {
        top: 65px;
      }
    }
  }
  &.vpnImg {
    .imgBg {
      max-width: 670px;
      @media (max-width: 1580px) {
        max-width: 570px;
      }
    }
    .imgMain {
      max-width: 660px;
      top: 35px;
      @media (max-width: 1580px) {
        max-width: 550px;
      }
      @media (max-width: 1199px) {
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  &.airdropsImg {
    .imgBg {
      max-width: 600px;
      @media (max-width: 1580px) {
        max-width: 520px;
      }
    }
    .imgMain {
      max-width: 470px;
      top: 27px;
      @media (max-width: 1580px) {
        max-width: 400px;
      }
      @media (max-width: 1199px) {
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  &.chatImg {
    .imgBg {
      max-width: 650px;
      @media (max-width: 1580px) {
        max-width: 550px;
      }
    }
    .imgMain {
      max-width: 638px;
      top: 62px;
      left: 40%;
      @media (max-width: 1580px) {
        max-width: 510px;
        left: 50%;
      }
      @media (max-width: 1199px) {
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  &.swapImg {
    .imgBg {
      max-width: 700px;
      @media (max-width: 1580px) {
        max-width: 550px;
      }
    }
    .imgMain {
      max-width: 560px;
      top: 0px;
      left: 56%;
      @media (max-width: 1580px) {
        max-width: 450px;
        left: 50%;
      }
      @media (max-width: 1199px) {
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  &.launchpadImg {
    .imgBg {
      max-width: 675px;
      @media (max-width: 1580px) {
        max-width: 550px;
      }
    }
    .imgMain {
      max-width: 640px;
      top: 120px;
      @media (max-width: 1580px) {
        max-width: 500px;
        top: 90px;
      }
      @media (max-width: 1199px) {
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  &.debitImg {
    .imgBg {
      @media (max-width: 1580px) {
        max-width: 550px;
      }
    }
    .imgMain {
      max-width: 580px;
      top: 20px;
      left: 60%;
      @media (max-width: 1580px) {
        max-width: 435px;
        top: 20px;
        left: 50%;
      }
      @media (max-width: 1199px) {
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}
