.faqSec .comnHeading {
    margin-bottom: 70px;
  }
  .faqSec_right {
    padding-top: 40px;
  }
  .faqSec_left img {
    width: 100%;
    height: 100%;
  }
  .faqSec .accordion-item {
    margin-bottom: 18px;
    box-shadow: 0px 0px 10px #0000001a;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 5px;
  }
  .faqSec .accordion-item .accordion-header {
    margin-bottom: 0;
    position: relative;
  }
  .toggleIcon::before {
    display: inline-block;
    content: "";
    position: absolute;
    background-repeat: no-repeat;
    top: 22px;
    right: 10px;
    width: 12px;
  }
  .iconMinus::before {
    background-image: url(../../../assets/images/minus.svg);
    height: 2px;
    top:28px;
  }
  .iconPlus::before {
    background-image: url(../../../assets/images/plus.svg);
    height: 12px;
  }
  .faqSec .accordion-item .accordion-button {
    background-color: transparent;
    text-align: left;
    white-space: pre-wrap;
    font-size: 14px;
    font-weight: 600;
    padding: 20px 25px;
    border: 0;
    width: 100%;
    color: rgba(255, 255, 255, 0.9);
    position: relative;
    display: flex;
    border-radius: 0;
    overflow-anchor: none;
  }

  .faqSec .accordion-item .accordion-button:after {
    flex-shrink: 0;
     width: 12px; 
     height: 12px;
    margin-left: auto;
    content: "";
    background-image: url(../../../assets/images/plus.svg);
    background-repeat: no-repeat;
    transition: transform .2s ease-in-out;
}

.faqSec .accordion-item .accordion-button:not(.collapsed):after {
  background-image: url(../../../assets/images/minus.svg) !important;
}
  
  .faqSec .accordion-item .accordion-button:focus {
    outline: 0;
  }
  
  .faqSec .accordion-body {
    font-size: 13px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.7);
    padding: 27px;
    padding-top: 0;
  }
  
  .faqSec .btn:hover,
  .faqSec .btn:focus {
    box-shadow: unset;
  }


  @media (max-width: 991px) {
    .comnHeading {
      margin-bottom: 60px;
    }
    .comnHeading .beforeTxt_Style {
      font-size: 60px;
      top: -32px;
    }
    .comnHeading p br {
      display: none;
    }
    .faqSec_left {
      max-width: 550px;
      margin: 0 auto;
    }
  }
  
  @media (max-width: 767px) {
    .faqSec .accordion-item .accordion-button{
      font-size: 12px;
    }
    .comnHeading .beforeTxt_Style {
      font-size: 36px;
    }
  }