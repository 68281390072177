@media (min-width: 576px){
.modal-dialog {
    max-width: 700px;
    margin: 1.75rem auto;
}
}
.connect_modal .modal-dialog .modal-content {
    background: rgb(0, 116, 221);
    background: #1b2f70;
    box-shadow: 0 0px 10px rgb(81 163 201 / 60%);
    color: #fff;
    border-radius: 10px;
    border: 0;
}
.ul_listing {
border-radius: 10px;
background-color: #adbdef;
 li{
display: flex;
justify-content:space-between;
padding: 0px 5px;
}
}

hr{
    margin: 5px 10px;
}
.next-modal{
    min-height:100px;
}