.ct-toast-error {
  svg {
    display: none !important;
  }
}
.ct-heading {
  display: none !important;
}
.ct-text-group-heading {
  .ct-text {
    font-size: 20px !important;
    font-weight: 600;
  }
}
.ct-toast .ct-toast-error {
  position: absolute;
  top: 300px !important;
}
// #ct-container {
//   position: absolute;
//   left: 50%;
//   top: 50%;
//   transform: translate(-50%, -50%);
//   padding: 10px;
// }
.ct-row {
  display: flex;
  justify-content: center;
  width: 100%;
}
#ct-container {
  width: 100%;
  top: 50%;
}
.ct-group {
  flex: none;
}
