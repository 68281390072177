.video-tur .card {
    border-radius: 10px;
    border: 2px solid #02EAB8;
    padding: 20px;
    box-shadow: 0px 0px 19px -3px rgb(2 234 184);
    -webkit-box-shadow: 0px 0px 19px -3px rgb(2 234 184);
    -moz-box-shadow: 0px 0px 19px -3px rgba(2,234,184,1.75);
    margin-bottom: 20px;
}

.font-weight-bold {
    font-weight: 700!important;
}
.text-center {
    text-align: center!important;
}

.video-tur a{
    color: #02eab8 !important;
}

.video-tur .border-container{
    border: 1px solid white;
    border-radius: 15px;
    padding: 50px;
}

@media (max-width: 768px) {
    .video-tur .border-container{
     
        padding: 20px;
    }
  }