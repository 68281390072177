// @import "../../components/themes/var.scss";

// .sell_row {
//   position: relative;
//   background: transparent
//     linear-gradient(
//       109deg,
//       rgb(11 216 188 / 53%) 0%,
//       rgb(12 215 188 / 53%) 0%,
//       rgb(113 60 247 / 53%) 100%
//     )
//     0% 0% no-repeat padding-box;
//   // border: 1px solid #707070;
//   border-radius: 49px;
//   // opacity: 0.53;
//   width: 100%;
//   min-height: 485px;
//   @media (max-width: 1280px) {
//     flex-direction: column;
//     border-radius: 30px;
//   }
//   .sellImg {
//     position: relative;
//     left: -120px;
//   }
//   > div {
//     position: static;
//     img {
//       position: absolute;
//       top: -144px;
//       left: -105px;
//       width: 818px;
//       max-width: 100%;
//       height: 710px;
//       @media (max-width: 1280px) {
//         position: unset;
//         height: unset;
//         width: unset;
//       }
//     }
//   }
// }


.sellSec {
  padding-bottom: 100px;
  .row {
    position: relative;
    background: transparent
      linear-gradient(
        109deg,
        rgb(11 216 188 / 53%) 0%,
        rgb(12 215 188 / 53%) 0%,
        rgb(113 60 247 / 53%) 100%
      )
      0% 0% no-repeat padding-box;
    // border: 1px solid #707070;
    border-radius: 49px;
    width: 100%;
    min-height: 485px;
    // @media (max-width: 1280px) {
    //   flex-direction: column;
    //   border-radius: 30px;
    // }
    .sellImg {
      position: relative;
      left: -120px;
    }
    > div {
      position: static;
      img {
        position: absolute;
        top: -144px;
        left: -105px;
        width: 818px;
        max-width: 100%;
        height: 710px;
        @media (max-width: 1280px) {
          position: unset;
          height: unset;
          width: unset;
        }
      }
    }
  }
  @media (max-width: 767px) { 
    .walletheading__title.common-heading {
      padding-top: 35px;
    }
  }
}