.progresbar {
  height: 50px;
  overflow: hidden;
  position: relative;
  width: 100%;
  border-radius: 50px;
  padding: 2px;
  background-image: linear-gradient(130deg, #02eab8, #8115ff);
}

.progress-container {
  margin-bottom: 25px;
}
.progress-container h3 {
  color: white;
  font-size: 26px;
  font-weight: 600;
  margin-top: 30px;
}
.progress-container p {
  color: white;
  font-weight: 600;
  font-size: 18px;
}

.progresbar .progress-parent {
  border-radius: 50px;
  height: calc(100% - 4px);
  width: calc(100% - 4px);
  background-color: white;
  margin: 2px;
  padding: 2px;
}

.progresbar .progress1 {
  background-image: linear-gradient(130deg, #02eab8, #8115ff);
  border-radius: 50px;
  float: right;
  height: 100%;
  width: 50%;
}

.img-shedule img{
  width: 100%;
}
.img_shedue_line1 {
  left: 200px;
  top: 110px;
  fill: #da1642;
  filter: invert(1);
}
.img_shedue_linefa {
  left: 200px;
  top: 145px;
  fill: #da1642;
  filter: invert(1);
}
.img_shedue_line2 {
  left: -205px;
  top: -34px;
  width: 125%;
  fill: #da1642;
  filter: invert(1);
}
.img_shedue_line3 {
  left: -42px;
  top: 42px;
  width: 63%;
  fill: #da1642;
  filter: invert(1);
}
.img_shedue_line4 {
  left: -40px;
  top: 115px;
  width: 63%;
  fill: #da1642;
  filter: invert(1);
}
.f-14 {
  font-size: 14px;
}
.mbtb {
  margin-bottom: 50px;
}
.main_right-Svg {
  position: absolute;
  right: -50px;
  top: -50px;
}
.main_right-Svg2 {
  position: absolute;
  right: 20px;
  top: -18px;
}
.main_right-Svg3 {
  position: absolute;
  right: -31px;
  top: 50px;
}
#myRedDIV1 .position-relative {
  margin-left: 15%;
}
#myRedDIV3 .position-relative {
  margin-right: 25%;
}

.my_id_1 {
  position: absolute;
  top: 50px;
}
.my_id_2 {
  position: absolute;
  top: 90px;
}

.slick-slider .slick-initialized {
  margin-top: 150px !important;
}

@media only screen and (max-width: 1199px) {
  .top_shedule_row .same_none {
    display: none !important;
  }
  #myRedDIV1 {
    order: 3;
  }
  #myRedDIV2 {
    order: 1;
  }
  #myRedDIV3 {
    order: 2;
  }
  #myRedDIV1 .position-relative {
    width: 100% !important;
    margin-bottom: 10px;
    margin-left: 0%;
  }
  #myRedDIV1 p {
    text-align: left !important;
    margin: 0px 0px 10px 10% !important;
    position: static;
  }
  #myRedDIV3 p {
    text-align: left !important;
    margin: 0px 0px 10px 10% !important;
    position: static;
  }
  #myRedDIV3 .position-relative {
    width: 100% !important;
    margin-bottom: 10px;
  }
  .befor_dot::before {
    content: "";
    background-color: #01bcd6;
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    left: -10px;
    top: 4px;
  }
  .fir-dot::before {
    background-color: #9ab9de;
  }

  .seco-dot::before {
    background-color: #8378bd;
  }
  .thrd-dot::before {
    background-color: #bfd8d1;
  }

  .fort-dot::before {
    background-color: #d7dfba;
  }

  .fift-dot::before {
    background-color: #6b3773;
  }
}
@media (max-width: 480px) {
  #myRedDIV1 {
    flex: 0 0 100% !important;
    margin-left: 20%;
  }
  #myRedDIV3 {
    flex: 0 0 100% !important;
    margin-left: 20%;
  }
}
@media (max-width: 767px) {
  #myRedDIV2 {
    flex: 0 0 100% !important;
    margin-bottom: 40px;
  }
}
@media (max-width:1200px) and (min-width:993px){
  .schedule-arange #myRedDIV1{ 
    margin-top: 62px;
  }
}
@media (max-width:992px) and (min-width:768px){
  .schedule-arange #myRedDIV1{ 
    margin-top: 80px;
  }
}
@media (max-width:768px) and (min-width:500px){
  .schedule-arange #myRedDIV1{ 
    margin-top: 60px;
  }
}
.schedule-arange .mobile-view{
  display: none;
}
@media (max-width:500px){
  .schedule-arange .mobile-view{
    display: block;
  }
  .dektop-view{
    display: none;

  }
}