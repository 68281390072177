.blog {
  padding: 50px 0;
}

.blog h1 {
  text-align: center;
  color: #08b2ec;
  margin-bottom: 40px;
  font-weight: 600;
}

.blog .card {
  position: relative;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0 0px 0px rgb(96 138 233 / 34%),
    0 14.7776px 33.4221px rgb(70 125 249 / 37%),
    0 11.3363px 17.869px rgb(70 125 249 / 39%),
    0 8.5216px 10.0172px rgb(70 125 249 / 43%),
    0 6.6501px 5.32008px rgb(70 125 249 / 39%),
    0 2.76726px 2.21381px rgb(70 125 249 / 42%);
  text-decoration: none;
  transition: 0.2s;
  overflow: hidden;
  border: none;
}

.blog .card .main-img {
  height: 450px;
  object-fit: fill;
}

@media (max-width: 600px) {
  .blog .card .main-img {
    height: 350px;
  }
}

.blog .card .blogCardText {
  padding: 20px;
}

.blog .card .blogCardText h3 {
  font-weight: 600;
  font-size: 25px;
}

.blog .card .blogCardText h6 {
  margin-top: 20px;
  color: #a4a3a4;
}

.blog .card .bage-tag {
  position: absolute;
  background-image: linear-gradient(to right, #7834f6, #09debc);
  padding: 6px 10px;
  border-radius: 5px;
  left: 20px;
  top: 20px;
  color: white;
  font-weight: 600;
}

.blog .small-card {
  padding: 10px 20px;
  margin-bottom: 30px;
}

.blog .small-card .bage-warning {
  background-color: #09debc;
  padding: 5px 10px;
  color: white;
  font-weight: 600;
  width: fit-content;
  border-radius: 5px;
  margin-bottom: 10px;
}

.blog .small-card h5 {
  font-size: 17px;
  color: black;
  margin-top: 10px;
  font-weight: 700;
}

.blog a {
  list-style: none;
  text-decoration: none;
}

.blog .catagiory {
  display: flex;
  align-items: center;
}
.blog .catagiory .nav-pills {
  padding: 5px;
  border-radius: 5px;
  background: #09debc;
  margin-right: 10%;
}
.blog .catagiory .nav-pills .nav-link {
  color: white;
}
.blog .catagiory .nav-pills .nav-link.active,
.blog .catagiory .nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff;
  background-image: linear-gradient(to right, #7834f6, #09bbde);
}

.blog .catagiory .selct-drop {
  min-width: 155px;
}
.blog .search-field {
  border: 1px solid #293eb5;
  border-radius: 10px;
  background-color: #172367;
  padding: 5px;
}
.blog .search-field .search-btn {
  color: #fff;
  position: absolute;
  right: 30px;
  top: 9px;
  border: none;
  background: none;
  padding: 2px 10px;
  z-index: 999;
  cursor: pointer;
}
.blog .search-field input {
  background: none;
  border: none;
  color: white;
}
.blog .form-control:focus {
  outline: none;
  box-shadow: none;
}
.blog .card-info {
  padding: 10px;
}
.blog .card-info h2 {
  font-size: 18px;
  font-weight: 600;
  color: black;
  margin-bottom: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.blog .card-info h6 {
  color: gray;
}
.blog .card {
  margin-bottom: 30px;
}
.blog .card .sm-img {
  height: 250px;
  object-fit: fill;
}
.leran-now {
  margin-top: 50px;
}
.leran-now .big-card {
  background-image: linear-gradient(to right, #7834f6, #09debc);
  padding: 30px;
  border-radius: 10px;
}
.leran-now .big-card h3 {
  font-weight: 700;
  color: white;
  font-size: 25px;
  margin-bottom: 20px;
}
.leran-now .big-card p {
  color: white;
}
.blog-details {
  padding: 50px 0;
}
.blog-details h1 {
  font-weight: 600;
  color: white;
  max-width: 600px;
}
.blog-details h6 {
  color: gray;
}
.blog-details .bage-theme {
  width: fit-content;
  background-image: linear-gradient(to right, #7834f6, #09debc);
  padding: 6px 10px;
  border-radius: 5px;
  color: white;
  font-weight: 600;
}
.blog-details .details-img {
  width: 100%;
  height: 550px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin: 20px 0;
  object-fit: cover;
}
.blog-details .social-icons ul {
  list-style: none;
  padding: 0;
}
.blog-details .social-icons ul li a {
  display: block;
  width: 42px;
  height: 42px;
  background-image: linear-gradient(to right, #7834f6, #09debc);
  border-radius: 8px;
  background-repeat: no-repeat;
  background-position: 50%;
  margin-bottom: 12px;
  transition: 0.2s ease;
  color: white !important;
  text-align: center;
  padding-top: 8px;
  font-size: 20px;
  cursor: pointer;
}

.blog-details .info-details .media img {
  width: 60px;
  height: 60px;
  object-fit: cover;
  margin-right: 20px;
}
.blog-details .info-details .media {
  margin-bottom: 20px;
}
.blog-details .info-details .media h6 {
  color: white;
  font-weight: 500;
}
.blog-details .info-details .media p {
  color: white;
}
.blog-details .info-details p {
  color: white;
  font-size: 17px;
  margin-top: 10px;
}
.blog-details .info-details h1 {
  color: #09debc;
  /* font-weight: 600; */
  margin: 10px 0;
}
.blog-details .info-details h2 {
  color: #09debc;
  /* font-weight: 600; */
  margin: 10px 0;
}
.blog-details .info-details h3 {
  color: #09debc;
  font-weight: 600;
  margin: 10px 0;
}
.blog-details .info-details h4 {
  color: #09debc;
  /* font-weight: 600; */
  margin: 10px 0;
}
.blog-details .info-details h5 {
  color: #09debc;
  /* font-weight: 600; */
  margin: 10px 0;
}
.blog-details .info-details ul,
.blog-details .info-details ol {
  list-style: none;
  margin-bottom: 20px;
}
.blog-details .info-details ul li,
.blog-details .info-details ol li {
  position: relative;
  padding-left: 20px;
  color: white;
  margin-bottom: 8px;
}
.blog-details .info-details ul li:before,
.blog-details .info-details ol li:before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  background-image: linear-gradient(to right, #7834f6, #09debc);
  border-radius: 100%;
  left: 0;
  top: 5px;
}
@media (max-width: 770px) {
  .social-icons ul {
    display: flex;
  }
  .social-icons ul li {
    margin-right: 10px;
  }
  .blog-details .details-img {
    height: 350px;
  }
}
@media (max-width: 600px) {
  .blog .catagiory .nav-pills {
    margin-right: 2%;
  }
  .blog .catagiory .nav-link {
    padding: 10px;
  }
}

.pagination {
  border-radius: 16px;
  box-shadow: 0 0px 0px rgb(96 138 233 / 34%),
    0 14.7776px 33.4221px rgb(70 125 249 / 37%),
    0 11.3363px 17.869px rgb(70 125 249 / 39%),
    0 8.5216px 10.0172px rgb(70 125 249 / 43%),
    0 6.6501px 5.32008px rgb(70 125 249 / 39%),
    0 2.76726px 2.21381px rgb(70 125 249 / 42%);
}
.pagination li a {
  background: transparent !important;
}
.pagination__link--disabled {
  opacity: 0.7;
}
.pagination__link--active {
  background-image: linear-gradient(to right, #7834f6, #09debc);
}

.select-categories {
  background: white;
  color: black;
}

.blog-details video {
  width: 100%;
}
