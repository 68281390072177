.loader {
    margin: 0 auto;
    // width: 60px;
    // height: 50px;
    text-align: center;
    font-size: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translateY(-50%) translateX(-50%);
    
    > div {
      height: 100%;
      width: 8px;
      display: inline-block;
      float: left;
      margin-left: 2px;
      -webkit-animation: delay 0.8s infinite ease-in-out;
      animation: delay 0.8s infinite ease-in-out;
    }
    img{
      width: 170px;
    }
    .bar1{
      background-color: #fff ;
    }
    .bar2{
      background-color: #fff;
      -webkit-animation-delay: -0.7s;
      animation-delay: -0.7s;
    }
    .bar3{
      background-color: #fff;
      -webkit-animation-delay: -0.6s;
      animation-delay: -0.6s;
    }
    .bar4{
      background-color: #fff;
      -webkit-animation-delay: -0.5s;
      animation-delay: -0.5s;
    }
    .bar5{
      background-color: #fff;
      -webkit-animation-delay: -0.4s;
      animation-delay: -0.4s;
    }
    .bar6{
      background-color: #fff;
      -webkit-animation-delay: -0.3s;
      animation-delay: -0.3s;
    }
  }
  
  
  @-webkit-keyframes delay {
    0%, 40%, 100% { -webkit-transform: scaleY(0.05) }  
    20% { -webkit-transform: scaleY(1.0) }
  }
  
  @keyframes delay {
    0%, 40%, 100% { 
      transform: scaleY(0.05);
      -webkit-transform: scaleY(0.05);
    }  20% { 
      transform: scaleY(1.0);
      -webkit-transform: scaleY(1.0);
    }
  }

  .overlayloadingIcon {
    width: 100%;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    z-index: 100000;
    height: 100%;
    background-color: rgba(42, 46, 47, 0.8);
  }

  .overlayloader {
    width: 100%;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    text-align: center;
    z-index: 100000;
    height: 100%;
    background-color: rgb(37 41 56);
    bottom: 0;
  }
  .zoom-in-zoom-out img {
    max-width: 80px;
  }
  .logo-hold {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }