// @import "../../assets/styles/_var.scss";
.modal_resendmail {
  min-height: 200px !important;
}
.input_resend {
  padding-left: 40px;
  border: 2px solid #02eab8;
  background: transparent;
  height: 50px;
}
.btn_padding {
  padding: 10px 30px !important;
}
.connect_modal {
  .modal-dialog {
    display: flex;
    flex: 1;
    height: auto;

    .modal-body {
      display: flex;
      flex-direction: column;
    }
    .modal-content {
      background: rgb(0, 116, 221);
      background: linear-gradient(
        90deg,
        rgba(0, 116, 221, 1) 0%,
        rgba(4, 198, 251, 1) 100%
      );
      box-shadow: 0 0px 10px rgba(81, 163, 201, 0.6);
      color: #fff;
      border-radius: 10px;
      border: 0;
      .modal-header {
        .modal-title {
          font-size: 20px;
          font-weight: 400;
        }
        .btn-close {
          cursor: pointer;
          outline: none;
          background-color: transparent;
          border: 0;
          opacity: 1;
          margin: 0;
          background-image: url(../../assets/images/svg/cross.svg);
          background-repeat: no-repeat;
          background-size: 100%;
          width: 20px;
          height: 20px;
        }
      }
    }

    .baseToken_style {
      ul {
        margin: 20px 0;
        padding-left: 0;
        li {
          display: block;
          margin-bottom: 10px;
          cursor: pointer;
          padding: 15px 20px;
          border: 1px solid rgb(0, 116, 221) !important;
          border-radius: 10px;
          text-decoration: none;
          color: #333;
          display: block;
          background: #f7fafe;
          position: relative;
          border: 0;
          img {
            width: 27px;
            position: absolute;
            right: 15px;
            top: 16px;
            height: 27px;
          }
        }
      }
      .add_new {
        a {
          color: #014b85;
          text-decoration: none;
        }
      }
    }
  }
}
.connect_modal.modal.show .modal-dialog {
  transform: translate(0%, -50%) !important;
  top: 50%;
}

.connect_modal1 {
  .modal-dialog {
    display: flex;
    flex: 1;
    height: 300px;

    .modal-body {
      display: flex;
      flex-direction: column;
    }
    .modal-content {
      background: rgb(0, 116, 221);
      background: linear-gradient(
        90deg,
        rgba(0, 116, 221, 1) 0%,
        rgba(4, 198, 251, 1) 100%
      );
      box-shadow: 0 0px 10px rgba(81, 163, 201, 0.6);
      color: #fff;
      border-radius: 10px;
      border: 0;
      .modal-header {
        .modal-title {
          font-size: 20px;
          font-weight: 400;
        }
        .btn-close {
          cursor: pointer;
          outline: none;
          background-color: transparent;
          border: 0;
          opacity: 1;
          margin: 0;
          background-image: url(../../assets/images/svg/cross.svg);
          background-repeat: no-repeat;
          background-size: 100%;
          width: 20px;
          height: 20px;
        }
      }
    }

    .baseToken_style {
      ul {
        margin: 20px 0;
        padding-left: 0;
        li {
          display: block;
          margin-bottom: 10px;
          cursor: pointer;
          padding: 15px 20px;
          border: 1px solid rgb(0, 116, 221) !important;
          border-radius: 10px;
          text-decoration: none;
          color: #333;
          display: block;
          background: #f7fafe;
          position: relative;
          border: 0;
          img {
            width: 27px;
            position: absolute;
            right: 15px;
            top: 16px;
            height: 27px;
          }
        }
      }
      .add_new {
        a {
          color: #014b85;
          text-decoration: none;
        }
      }
    }
  }
}
.main_itembtn {
  display: flex;
  justify-content: space-between;
  @media (max-width: 480px) {
    display: block;
  }
}
.ht_fix {
  min-height: auto !important;
  padding: 70px 40px !important;
}
