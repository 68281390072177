.token-head span{
    font-size: 24px;
    color: rgb(255, 255, 255);
    font-weight: 700;
}

.SmartContracts .left-heading{
    text-align: right;
    color: rgb(255, 255, 255);
    font-weight: 700;
    margin-bottom: 15px;

}

.SmartContracts p{
    color: white;
}
.smart-contract-info p {
    margin: 0px;
    margin-top: 20px;
}

@media  (max-width:800px) {
    .SmartContracts .left-heading{
        text-align: left!important;
        margin-bottom: 0px;
        margin-top: 20px;
    
    }  
}