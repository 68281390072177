/* roadmap section  */
.roadmapSec {
    background-image: url(../../assets/images/banner-bg.svg);
    background-repeat: no-repeat;
    background-size: cover;
  }
  .roadmapSec.comnPadd {
    padding-bottom: 20px;
    background-image: linear-gradient(to right, #162266, #10194b);
    overflow: hidden;
  }
  
  .roadmapSec .comnHeading {
    margin-bottom: 80px;
  }
  
  .roadmap_Slider {
    color: #fff;
    position: relative;
  }
  
  .roadmap_Slider::before {
    content: "";
    position: absolute;
    top: 58px;
    width: 104%;
    left: 50%;
    transform: translateX(-50%);
    height: 2px;
    background: linear-gradient(270deg, #15205d 0%, #01eab8 51%, #15205d 100%);
    z-index: 0;
  }
  .roadmapWrap {
    min-height: 280px;
    display: flex !important;
    align-items: flex-end;
    position: relative;
  }
  .roadmapWrap::before {
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    background-color: #01eab8;
    width: 2px;
    height: 117px;
    top: 8px;
    z-index: 0;
  }
  .roadmapWrap .phase {
    color: #fff;
    background: linear-gradient(272deg, #01eab8 0%, #6072b2 100%);
    border-radius: 28px;
    font-size: 13px;
    font-weight: 500;
    padding: 10px 25px;
    position: absolute;
    white-space: nowrap;
    top: -55px;
    left: 50%;
    transform: translateX(-50%);
  }
  .roadmapWrap .roadmapWrap_content {
    background-color: #29388b;
    padding: 25px;
    box-shadow: 0px 0px 20px #0000000f;
    border-radius: 10px;
    width: 100%;
    min-height: 155px;
    z-index: 1;
  }
  
  .roadmapWrap_content ul {
    padding-left: 20px;
    margin-bottom: 0;
    font-size: 18px;
  }
  
  .roadmapWrap_content ul li::marker {
    color: #01eab8;
    font-size: 18px;
  }
  .roadmapWrap_content::after {
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    background-color: #01eab8;
  }
  
  .roadmapWrap_content::after {
    border-radius: 50%;
    border: 2px solid #15205d;
    width: 15px;
    height: 15px;
    top: -8px;
  }
  
  /* slider css */
  
  .roadmap_Slider .slick-slider .slick-arrow {
    top: 58px;
    width: 34px;
    height: 34px;
    border-radius: 50%;
    background: #01eab8;
    z-index: 1;
  }
  .roadmap_Slider .slick-slider .slick-arrow::before {
    content: "";
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-image: url("../../assets/images/arrow.svg");
    background-repeat: no-repeat;
    width: 12px;
    height: 12px;
    opacity: 1;
  }
  
  .roadmap_Slider .slick-slider .slick-prev::before {
    transform: rotate(-180deg);
  }
  .roadmap_Slider .slick-slider .slick-prev {
    left: -10px;
  }
  .roadmap_Slider .slick-slider .slick-next {
    right: -10px;
  }
  
  .roadmap_Slider .slick-slider .slick-slide {
    padding: 60px 10px;
  }
  
  /* responsive  */
  @media (min-width: 1500px) {
    .roadmapSec .container {
      max-width: fit-content !important;
      padding: 0px 50px;
    }
  }
  
  @media (max-width: 991px) {
    .comnHeading {
      margin-bottom: 60px;
    }
    .comnHeading .beforeTxt_Style {
      font-size: 60px;
      top: -32px;
    }
    .comnHeading p br {
      display: none;
    }
    
  }
  
  @media (max-width: 767px) {
    .comnPadd {
      padding: 50px 0;
    }
  }
  
  