/* token distribution section  */
.comnPadd {
  padding: 70px 0;
  background-image: linear-gradient(to right, #162266, #10194b);
  overflow: hidden;
}
.tokenDistb_sec .container .row {
  align-items: center;
  margin: 0;
}

.tokenDistb_left img {
  width: 100%;
  height: 100%;
}

.tokenDistb_right .row {
  gap: 15px 0;
}
.distbList {
  margin: 0 -8px;
  background-color: #29388b;
  color: white;
  padding: 5px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 10px 10px #0000000a;
  border: 1px solid #00000000;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
  min-height: 50px;
}

.distbList::before {
  position: absolute;
  content: "";
  display: inline-block;
  width: 5px;
  height: 110%;
  left: 0;
}
.distbList.bg1::before {
  background-color: #a682fe;
}
.distbList.bg2::before {
  background-color: #11a2bd;
}
.distbList.bg3::before {
  background-color: #30e89c;
}
.distbList.bg4::before {
  background-color: #c65077;
}
.distbList.bg5::before {
  background-color: #b171cf;
}
.distbList.bg6::before {
  background-color: #01c3ff;
}
.distbList.bg7::before {
  background-color: #fa8c4e;
}
.distbList.bg8::before {
  background-color: #49909d;
}

.distbList span {
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
}
.distbList span:last-child {
  font-size: 16px;
  font-weight: 600;
}

.comnHeading {
  text-align: center;
  color: #fff;
  margin-bottom: 102px;
  position: relative;
  z-index: 1;
}
.comnHeading h2 {
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 34px;
  position: relative;
}
.comnHeading .beforeTxt_Style {
  z-index: 0;
  position: absolute;
  font-size: 80px;
  font-weight: 700;
  color: rgba(255, 255, 255, 0.07);
  /* width: 100%; */
  top: -52px;
  left: 50%;
  transform: translateX(-50%);
  width: fit-content;
}
.comnHeading h2::after {
  display: inline-block;
  position: absolute;
  content: "";
  width: 46px;
  height: 2px;
  background-color: #01eab8;
  left: 50%;
  transform: translateX(-50%);
  bottom: -15px;
}
.comnHeading p {
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  margin-bottom: 0;
}

@media (max-width: 1199px) {
  .tokenDistb_left {
    padding-right: 0;
    max-width: 550px;
    margin: 0 auto;
    margin-bottom: 30px;
  }
}
@media (max-width: 991px) {
  .comnHeading {
    margin-bottom: 60px;
  }
  .comnHeading .beforeTxt_Style {
    font-size: 60px;
    top: -32px;
  }
  .comnHeading p br {
    display: none;
  }
  .faqSec_left {
    max-width: 550px;
    margin: 0 auto;
  }
}
@media (min-width: 1500px) {
  .tokenDistb_sec .container {
    max-width: fit-content !important;
    padding: 0px 50px;
  }
}

@media (max-width: 1199px) {
  .tokenDistb_left {
    padding-right: 0;
    max-width: 550px;
    margin: 0 auto;
    margin-bottom: 30px;
  }
}

@media (max-width: 767px) {
  .comnPadd {
    padding: 50px 0;
  }
  .faqSec .accordion-item .accordion-button {
    font-size: 12px;
  }
}
.btn_index {
  z-index: 999 !important;
}

.open-menu {
  position: absolute;
  top: -47px;
  color: white;
  display: none;
  right: 1px;
  /* left: 384px; */
  /* left: inherit !important;
  right: 6px; */
  /* @media (max-width: 420px) {
    left: 200px !important;
    right: 1px;
  } */
}
