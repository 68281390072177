@import url("https://fonts.googleapis.com/css?family=Quicksand:light,regular,medium,semi,bold,italic");

body,
.quicksand-font,
h1,
h2,
h3,
h4,
h4,
h5,
h6,
p,
a,
button,
span {
  font-family: "Quicksand", sans-serif !important;
}
.page-heading {
  font-size: 42px;
}
.page-heading span {
  color: #8115ff;
}
.page {
  padding-top: 150px !important;
  padding-bottom: 100px !important;
  padding: 60px 0;
}

/* .dasboard .sidebar ul {
  list-style: none;
  text-decoration: none;
}
.dasboard .sidebar ul li {
  padding: 6px 10px;
}
.dasboard .sidebar ul li a {
  color: black;
  font-weight: 600;
} */
.dasboard .inner-card .card {
  border-radius: 10px !important;
  border: 2px solid #02eab8 !important;
  padding: 20px !important;
  background-color: white !important;
  /* box-shadow: 0px 0px 19px -3px rgb(2 234 184);
  -webkit-box-shadow: 0px 0px 19px -3px rgb(2 234 184);
  -moz-box-shadow: 0px 0px 19px -3px rgba(2, 234, 184, 1.75); */
  /* text-align: center; */
}

/*wallat*/
.wllat .img-sahdow {
  transform: rotate(330deg);
  filter: drop-shadow(0 0 30px #02eab8);
}

/*rafral*/
.rafral .card {
  border-radius: 10px;
  border: 2px solid #02eab8;
  padding: 20px;
  box-shadow: 0px 0px 19px -3px rgb(2 234 184);
  -webkit-box-shadow: 0px 0px 19px -3px rgb(2 234 184);
  -moz-box-shadow: 0px 0px 19px -3px rgba(2, 234, 184, 1.75);
  position: relative;
  text-align: center;
}
.rafral .card .card-head {
  align-items: center;
  /* background: #000; */
  border: 2px solid #02eab8;
  border-bottom: 0;
  border-top-left-radius: 126px;
  border-top-right-radius: 126px;
  display: flex;
  height: 63px;
  justify-content: center;
  left: calc(50% - 42px);
  position: absolute;
  top: -63px;
  padding: 16px;
  box-shadow: 0px 0px 19px -3px rgb(2 234 184);
  -webkit-box-shadow: 0px 0px 19px -3px rgb(2 234 184);
  -moz-box-shadow: 0px 0px 19px -3px rgba(2, 234, 184, 1.75);
}
.img-sahow {
  filter: drop-shadow(0 0 30px #02eab8);
}

/*refferal-link*/
.refferal-link input {
  border-radius: 40px;
  border: 2px solid #02eab8;
  height: 45px;
  box-shadow: 0px 0px 19px -3px rgb(2 234 184);
  -webkit-box-shadow: 0px 0px 19px -3px rgb(2 234 184);
  -moz-box-shadow: 0px 0px 19px -3px rgba(2, 234, 184, 1.75);
}
.refferal-link .social ul {
  list-style: non;
  text-decoration: none;
  display: flex;
  align-items: center;
}
.refferal-link .social ul li {
  margin: 0 10px;
  list-style: none;
}
.refferal-link .social ul li svg {
  width: 30px;
}

/*my-cupcon*/
.my-cupcon table thead tr {
  background-color: #02eab8;
  color: white;
}
.my-cupcon table tbody tr:nth-child(even) {
  background: aliceblue;
}

/**/

/*sidebar*/

.sidebar .box {
  height: 100vh;
  z-index: 1001;
  background: #ffff;
  overflow: hidden;
  text-align: left;
  -webkit-transition: -webkit-transform 250ms ease-in-out;
  transition: transform 250ms ease-in-out;
  display: none;
}
.sidebar .active {
  display: block;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
  -moz-box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3),
    0 15px 12px rgba(0, 0, 0, 0.22);
  -webkit-box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3),
    0 15px 12px rgba(0, 0, 0, 0.22);
}
.open-menu {
  display: inline-block;
}
html {
  scroll-behavior: smooth;
}
.dasboard {
}
@media (max-width: 750px) {
  .sidebar .box {
    position: absolute;
    width: 100%;
  }
}

.blue-gradiant {
  background-image: linear-gradient(to right, #1a2770, #10194b);
}

.contact-us {
  padding: 50px 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: linear-gradient(to right, #162266, #10194b);
}
.blue-text {
  color: #02eab8;
}
.contact-us .contact-card {
  border-radius: 10px;
  border: 2px solid #02eab8;
  padding: 20px;
  box-shadow: 0px 0px 19px -3px rgb(2 234 184);
  -webkit-box-shadow: 0px 0px 19px -3px rgb(2 234 184);
  -moz-box-shadow: 0px 0px 19px -3px rgba(2, 234, 184, 1.75);
}
.contact-us .contact-card .form-group {
  position: relative;
}
.contact-us .contact-card .form-group input {
  padding-left: 40px;
  border: 2px solid #02eab8;
  background: transparent;
  height: 50px;
  color: #ffff;
}
.contact-us .contact-card .form-group textarea {
  padding-left: 40px;
  border: 2px solid #02eab8;
  background: transparent;
  resize: none;
  color: #ffff;
}
.contact-us .contact-card .form-group i {
  position: absolute;
  color: #02eab8;
  top: 17px;
  left: 15px;
}
.contact-us .contact-card .form-group ::placeholder {
  color: #ffff;
}
.contact-us .contact-card .theme-btn {
  border: 2px solid #02eab8;
  padding: 9px 50px;
}
.contact-us .contact-info i {
  color: #02eab8 !important;
  font-size: 25px !important;
  margin-right: 10px !important;
}
.contact-us .contact-info p {
  color: white;
}

.same-page-heading {
  font-size: 72px;
  font-weight: 500;
  line-height: 1;
}

.available {
  font-size: 25px;
  font-weight: 500;
}

.title {
  font-size: 20px;
  margin-bottom: 20px;
  /* margin-top: 20px; */
}

.theme-bg-color {
  background-color: #1a2771 !important;
}
.video-img {
  background-size: inherit;
  background-repeat: no-repeat;
  width: 100%;
  position: absolute;
}
@media (max-width: 750px) {
  .video-img {
    display: none;
  }
}
.top-bg {
  height: 60%;
  top: 25%;
  animation: mymove 3s;
  animation-iteration-count: infinite;
}

@keyframes mymove {
  0% {
    top: 100px;
  }
  50% {
    top: 200px;
  }
  /* 50%  {top: 100px;} */
  /* 75%  {top: 200px;} */
  100% {
    top: 100px;
  }
}
.center-bg {
  height: 60%;
  /* top: 25%; */
  animation: mymoves 4s;
  animation-iteration-count: infinite;
}
@keyframes mymoves {
  0% {
    top: 150px;
  }
  50% {
    top: 200px;
  }
  /* 50%  {bottom: 100px;} */
  /* 75%  {bottom: 200px;} */
  100% {
    top: 150px;
  }
}
.bottom-bg {
  height: 60%;
  /* top: 20%; */
  animation: mymovee 3s;
  animation-iteration-count: infinite;
}
@keyframes mymovee {
  0% {
    bottom: 0px;
  }
  50% {
    bottom: 150px;
  }
  /* 50%  {bottom: 100px;} */
  /* 75%  {bottom: 200px;} */
  100% {
    bottom: 0px;
  }
}
.hover-area {
  position: relative;
  min-height: 165px;
  transition: all 0.9s;
}
.hover-area .about-info {
  opacity: 0;
  z-index: 99;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: #10194b;
  padding: 5px;
  transition: all 1s;
}
.hover-area .about-info p {
  font-size: 12px;
  color: white;
}
.hover-area:hover .about-info {
  opacity: 1;
  transition: all 1s;
  position: absolute;
  top: -176px;
  border-radius: 10px;
  border: 1px solid #29b5b4;
}
.about-info::before {
  content: "";
  position: absolute;
  border-top: solid 13px #29b5b4;
  border-left: solid 13px transparent;
  border-right: solid 13px transparent;
  bottom: -14px;
  left: 50%;
  transform: translateX(-50%);
}

iframe {
  display: none !important;
}

.comnHeading {
  text-align: center;
  color: #fff;
  margin-bottom: 102px;
  position: relative;
  z-index: 1;
}
.comnHeading h2 {
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 34px;
  position: relative;
}
.comnHeading .beforeTxt_Style {
  z-index: 0;
  position: absolute;
  font-size: 80px;
  font-weight: 700;
  color: rgba(255, 255, 255, 0.07);
  width: 100%;
  top: -52px;
  left: 50%;
  transform: translateX(-50%);
}
.comnHeading h2::after {
  display: inline-block;
  position: absolute;
  content: "";
  width: 46px;
  height: 2px;
  background-color: #01eab8;
  left: 50%;
  transform: translateX(-50%);
  bottom: -15px;
}
.comnHeading p {
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  margin-bottom: 0;
}

.comnPadd {
  padding: 70px 0;
  background-image: linear-gradient(to right, #162266, #10194b);
  overflow: hidden;
}

.theme-btn {
  background-image: linear-gradient(to right, #09debc, #7834f6);
  border-radius: 40px;
  padding: 8px 25px !important;
  width: fit-content !important;
  color: white;
  border: none;
}

@media (max-width: 991px) {
  .comnHeading {
    margin-bottom: 60px;
  }
  .comnHeading .beforeTxt_Style {
    font-size: 60px;
    top: -32px;
  }
  .comnHeading p br {
    display: none;
  }
}

@media (max-width: 767px) {
  .comnPadd {
    padding: 50px 0;
  }
  .faqSec .accordion-item .accordion-button {
    font-size: 12px;
  }
}

.centerTxt {
  padding-top: 10px;
  display: block;
  text-align: center;
  text-decoration: underline !important;
  font-weight: 500;
  cursor: pointer;
}
.full-height {
  min-height: 85vh;
  background-image: linear-gradient(to right, #162266, #10194b);
}

.Loader img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ReferralCode input {
  padding-left: 40px;
  border: 2px solid #02eab8;
  background: transparent;
  height: 50px;
  color: white;
}

.referral-code {
  width: 50%;
}

@media (max-width: 992px) {
  .referral-code {
    width: 100%;
  }
}
