.AdminMain{
    background: url(../../assets/images/banner-bg.svg) top center;
    min-height: calc(100vh - 85px);
    width: 100%;
    .row{
        height: 100%;
        .sidebarAdmin{
            background: #1a2770 ;
            height: 100%;
            min-height:100vh;
            @media (max-width: 991px) {
                display: none;
            }
            ul{
                padding-top: 30px;
                padding-left: 20px; 
                li{
                    list-style: none;
                    margin-bottom: 20px;
                    .dropdown-item{
                        color: #fff;
                        text-decoration: none;
                        font-size: 16px;
                        background: transparent;
                        &:hover{
                            color: #02eab8;
                        }
                    }
                 }
            }
        }
    }
}
.graphclass canvas{
    position: static;
}